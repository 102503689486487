export const activityTypes = [
	{
		icon: 'cil-phone',
		value: 'CALL',
		text: 'Anruf'
	},
	{
		icon: 'cil-people',
		value: 'MEETING',
		text: 'Meeting'
	},
	{
		icon: 'cil-clock',
		value: 'TASK',
		text: 'Aufgabe'
	},
	{
		icon: 'cil-flag-alt',
		value: 'DEADLINE',
		text: 'Frist'
	},
	{
		icon: 'cil-paper-plane',
		value: 'EMAIL',
		text: 'E-MAIL'
	},
	{
		icon: 'cil-restaurant',
		value: 'LUNCH',
		text: 'Mittagessen'
	},
];

export const dealStatus = [
	{
		value: 'WON',
		text: 'Gewonnen',
	},
	{
		value: 'Lost',
		text: 'Verloren',
	},
	{
		value: 'PROCESSING',
		text: 'In Bearbeitung',
	}
]
