<template>
  <CRow class="schedule-activity-widget">
    <CCol md="8">
      <!-- meeting name -->
      <CRow class="pl-3">
        <CCol md="12">
          <CInput
            v-model="title"
            :placeholder="[[getPlaceholder(type)]]"
            v-on:change="validateFields()"
          />
        </CCol>

        <CCol md="12" style="margin-top: -10px;" v-if="errors.title && submitted">
          <label class="text-danger">
            Bitte geben Sie den Titel ein
          </label>
        </CCol>
      </CRow>

      <!-- meeting type icon -->
      <CRow class="pl-3">
        <CCol md="12" class="mb-3">
          <CButton
            size="sm"
            color="dark"
            :variant="type === item.value ? '' : 'outline'"
            v-for="(item, index) of activityTypes"
            v-on:click="type = item.value"
            v-bind:key="index"
            v-c-tooltip="item.text"
          >
            <CIcon :name="item.icon"/>
          </CButton>
        </CCol>
      </CRow>

      <!-- time set area -->
      <CRow class="pl-3">
        <CIcon class="position-absolute" style="margin-left: -7px; margin-top: 7px;" name="cil-clock"></CIcon>
        <CCol md="4" class="date-selector">
          <datepicker
            :language="datepickerLangGerman"
            :monday-first="true"
            v-model="startDate"
            format="dd/MM/yyyy"
          />
        </CCol>
        <CCol md="2" class="time-selector">
          <CSelect
            :options="times"
            :value="startTime"
            class="no-chevron"
            @update:value="startTime = $event"
            v-on:change="validateFields()"
          />
        </CCol>
        <CCol md="2" class="time-selector">
          <CSelect
            :options="times"
            :value="endTime"
            class="no-chevron"
            @update:value="endTime = $event"
            v-on:change="validateFields()"
          />
        </CCol>
        <CCol md="4" class="date-selector">
          <datepicker
            :language="datepickerLangGerman"
            :monday-first="true"
            v-model="endDate"
            format="dd/MM/yyyy"
          />
        </CCol>
        <CCol md="12" style="margin-top: -10px;" v-if="errors.time && submitted">
          <label class="text-danger">
            Bitte planen Sie ein richtiges Datum ein
          </label>
        </CCol>
      </CRow>

      <!-- add guest, location, video call, description -->
      <CRow class="pl-3">
        <CCol md="12" v-if="checkAllEmpty()">
          <label>
            Hinzufügen
            <CLink v-on:click="focusOnExtra('guests')">Gäste</CLink>
            ,
            <CLink v-on:click="focusOnExtra('location')">Standort</CLink>
            ,
            <CLink v-on:click="focusOnExtra('description')">Beschreibung</CLink>
          </label>
        </CCol>

        <!-- guest select -->
        <CCol md="7" class="mb-3" v-if="((focusedExtra === 'guests') || (selectedGuests.length > 0))">
          <CIcon class="position-absolute" style="margin-left: -22px; margin-top: 7px;" name="cil-user"></CIcon>
          <multi-select
            :options="guests"
            :selected-options="selectedGuests"
            placeholder="Gäste hinzufügen"
            @select="onSelect"
          >
          </multi-select>
        </CCol>
        <CCol md="5" class="mb-3 d-flex" v-if="((focusedExtra === 'guests') || (selectedGuests.length > 0))">
          <input
            class="form-control"
            type="text"
            v-model="newGuest"
            placeholder="Neuer Gast"
            style="padding-right: 40px;"
            v-on:keydown="onKeyDown"
          />
          <CButton
            size="sm"
            color="success"
            class="position-absolute"
            style="right: 15px;top: 1px;cursor: pointer;"
            v-on:click="insertAsNewOption(newGuest)"
          >
            <CIcon name="cil-plus"/>
          </CButton>
        </CCol>

        <CCol md="7" v-if="errors.newGuest">

        </CCol>

        <CCol md="5" v-if="errors.newGuest && newGuestAddTried" style="margin-top: -10px;">
          <label class="text-danger">
            Die E-Mail Adresse ist nicht korrekt
          </label>
        </CCol>

        <CCol class="mb-3" md="12"
              v-if="!checkAllEmpty() && !((focusedExtra === 'guests') || (selectedGuests.length > 0))">
          <label>
            <CLink v-on:click="focusOnExtra('guests')">Hinzufügen Gäste</CLink>
          </label>
        </CCol>

        <CCol md="12" class="mb-3" v-if="((focusedExtra === 'location') || location)">
          <CIcon class="position-absolute" style="margin-left: -22px; margin-top: 7px;" name="cis-location-pin"></CIcon>
          <CInput
            v-model="location"
            placeholder="Standort hinzufügen"
          />
        </CCol>

        <CCol class="mb-3" md="12" v-if="!checkAllEmpty() && !((focusedExtra === 'location') || location)">
          <label>
            <CLink v-on:click="focusOnExtra('location')">Hinzufügen Standort</CLink>
          </label>
        </CCol>

        <!-- description -->
        <CCol md="12" v-if="((focusedExtra === 'description') || description)">
          <CIcon class="position-absolute" style="margin-left: -22px; margin-top: 7px;" name="cil-list"></CIcon>
          <WysiwygEditor
            v-model="description"
          />
        </CCol>
        <CCol md="12" class="mb-3" style="margin-top: -15px" v-if="focusedExtra === 'description' || description">
          Die Beschreibung wird mit Ihrem Kalender synchronisiert und ist für alle Gäste sichtbar
        </CCol>

        <CCol class="mb-3" md="12" v-if="!checkAllEmpty() && !((focusedExtra === 'description') || description)">
          <label>
            <CLink v-on:click="focusOnExtra('description')">Hinzufügen Beschreibung</CLink>
          </label>
        </CCol>
      </CRow>

      <!-- busy or free -->
      <CRow class="pl-3">
        <CIcon class="position-absolute" style="margin-left: -7px; margin-top: 7px;" name="cil-library"></CIcon>
        <CCol md="4">
          <CSelect
            :options="busyItems"
            :value.sync="freeType"
          />
        </CCol>
      </CRow>
      <!--notes-->
      <CRow class="pl-3">
        <CIcon class="position-absolute" style="margin-left: -7px; margin-top: 7px;" name="cil-notes"></CIcon>
        <CCol md="12">
          <WysiwygEditor
            required
            v-model="notes"
          />
        </CCol>
        <CCol md="12" class="mb-3" style="margin-top: -15px;">
          Notizen sind privat und nur innerhalb Ihres Accounts sichtbar
        </CCol>
      </CRow>

      <!--deal select-->
      <CRow class="pl-3">
        <CIcon class="position-absolute" style="margin-left: -7px; margin-top: 7px;" name="cil-paperclip"></CIcon>
        <CCol md="12" class="mb-3">
          <model-select
            :options="leads"
            v-model="lead"
            placeholder="Deal oder Lead"
          >
          </model-select>
        </CCol>
      </CRow>
      <!--group select: only show when lead is not selected-->
      <CRow class="pl-3" v-if="!lead.value">
        <CIcon class="position-absolute" style="margin-left: -7px; margin-top: 7px;" name="cil-group"></CIcon>
        <CCol md="12" class="mb-3">
          <model-select
            :options="groups"
            v-model="selectedGroup"
            placeholder="Organisation"
          >
          </model-select>
        </CCol>
      </CRow>
      <!--people select: only show when lead is not selected-->
      <CRow class="pl-3" v-if="!lead.value">
        <CIcon class="position-absolute" style="margin-left: -7px; margin-top: 7px;" name="cil-user"></CIcon>
        <CCol md="12" class="mb-3">
          <multi-select
            :options="peoples"
            :selected-options="selectedPeoples"
            placeholder="Personen auswählen"
            @select="onSelectPeople"
          >
          </multi-select>
        </CCol>
      </CRow>
      <!-- mark as done -->
      <CRow class="pl-3">
        <CCol md="12">
          <CInputCheckbox
            label="Als erledigt markieren"
            :checked="done"
            @update:checked="done = !done"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol md="4">
      <div class="calendar-title-bar">
        <CButton
          size="sm"
          @click="calendarPrevious()"
          style="box-shadow: none"
        >
          <CIcon
            name="cil-arrow-circle-left"
          />
        </CButton>
        <span>
          {{this.selectedDate.toLocaleDateString('de-DE')}}
        </span>
        <CButton
          size="sm"
          style="box-shadow: none"
          @click="calendarNext()"
        >
          <CIcon
            name="cil-arrow-circle-right"
          />
        </CButton>
      </div>
      <vue-cal
        ref="vuecal"
        hide-view-selector
        :selected-date="selectedDate"
        :time-from="6 * 60"
        :time-to="22 * 60"
        :time-step="60"
        :disable-views="['years', 'year', 'month', 'week']"
        hide-weekends
        :editable-events="{ title: false, drag: true, resize: true, delete: false, create: false }"
        :min-event-width="minEventWidth"
        :events="events"
        style="height: 100%;max-height: 750px;"
        :snap-to-time="15"
        @event-duration-change="logEvents('event-duration-change', $event)"
        @event-drop="logEvents('event-drop', $event)"
      >
      </vue-cal>
    </CCol>
  </CRow>
</template>

<script>
import WysiwygEditor from '@/components/WysiwygEditor'
import {ModelSelect, MultiSelect} from 'vue-search-select'
import {activityTypes} from "../../../config/constant"
import axios from "axios"
import Datepicker from 'vuejs-datepicker'
import { de } from 'vuejs-datepicker/dist/locale'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import 'vue-cal/dist/drag-and-drop.es.js'
import commons from "../../../commons"

export default {
  mounted() {
    this.times.push("")
    for (let i = 0; i < 24; i++) {
      this.times.push({
        value: ("0" + i).slice(-2) + ':00',
        text: ("0" + i).slice(-2) + ':00'
      })
      this.times.push({
        value: ("0" + i).slice(-2) + ':15',
        text: ("0" + i).slice(-2) + ':15'
      })
      this.times.push({
        value: ("0" + i).slice(-2) + ':30',
        text: ("0" + i).slice(-2) + ':30'
      })
      this.times.push({
        value: ("0" + i).slice(-2) + ':45',
        text: ("0" + i).slice(-2) + ':45'
      })
    }

    this.loadPeoples()
    this.loadGroups()
    this.loadLeads()
  },
  props: [
    'closeModal',
    'applyUpdate'
  ],
  components: {
    ModelSelect,
    MultiSelect,
    WysiwygEditor,
    Datepicker,
    VueCal,
  },
  watch: {
  selectedDate: function (val) {
    console.log('Selected date changed:', val);
    this.getActivitiesOnSelectedDate();
  },
  startDate: function (date) {
    console.log('Start date changed:', date);
    this.endDate = new Date(date.getTime() + 15 * 60000)
    this.selectedDate = date
  },
  startTime: function (time) {
    console.log('Start time changed:', time);
    const date = this.makeDateTime(this.startDate, time)
    this.endDate = new Date(date.getTime() + 15 * 60000)
    this.validateFields()
  }
},

  computed: {
    events: function () {
      return [
        ...this.alreadyCreatedEvents,
        {
          start: this.makeDateTime(this.startDate, this.startTime),
          end: this.makeDateTime(this.endDate, this.endTime),
          title: this.getPlaceholder(this.type) + ' - ' + this.title,
          content: '',
          class: 'current',
        },
      ]
    },
  },
  data: () => ({
    datepickerLangGerman: de,
    alreadyCreatedEvents: [],
    minEventWidth: 0,
    // selected date on calendar
    selectedDate: new Date(),

    /* activity title */
    title: '',
    /* possible activity types */
    activityTypes: activityTypes,
    /* selected activity type */
    type: activityTypes[0].value,
    /* possible time select options */
    times: [],
    startDate: new Date(),
    endDate: new Date(),
    startTime: '',
    endTime: '',
    /* busy, free options */
    busyItems: [
      {value: 'FREE', label: 'Frei'},
      {value: 'BUSY', label: 'Beschäftigt'}
    ],
    freeType: 'FREE',
    /* description */
    description: '',
    /* notes */
    notes: '',
    /* possible lead options */
    leads: [],
    /* selected lead option */
    lead: {
      value: null,
      text: ''
    },
    /* location */
    location: '',
    done: false,
    /* possible guest options */
    guests: [],
    selectedGuests: [],
    lastSelectGuest: {},
    /* possible people options */
    peoples: [],
    selectedPeoples: [],
    lastSelectPeople: {},
    /* possible group options */
    groups: [],
    selectedGroup: {
      value: null,
      text: ''
    },
    /* for deal on selected extra information input */
    focusedExtra: '',
    newGuest: '',
    /* errors */
    errors: {},
    submitted: false,
    newGuestAddTried: false,
    // set when edit activity
    selectedActivityId: null,
  }),
  methods: {
    logEvents(type, e) {
      this.startDate = e.event.start
      this.startTime = ("0" + this.startDate.getHours()).slice(-2) + ':' + ("0" + ((this.startDate.getMinutes()) - (this.startDate.getMinutes() % 15))).slice(-2)
      this.endDate = e.event.end
      this.endTime = ("0" + this.endDate.getHours()).slice(-2) + ':' + ("0" + ((this.endDate.getMinutes()) - (this.endDate.getMinutes() % 15))).slice(-2)
    },
    getPlaceholder(type) {
      const selectedActivity = this.activityTypes.find(item => item.value === type)
      return selectedActivity ? selectedActivity.text : 'Unbekannt'; // Fallback, falls type nicht gefunden wird
    },
    async loadLeads() {
  try {
    const leadResponse = await axios.get('/lead');
    const dealResponse = await axios.get('/deal');
    let content = [];

    // Überprüfen der Struktur der API-Antwort für Leads
    if (leadResponse.data && Array.isArray(leadResponse.data.content)) {
      leadResponse.data.content.forEach(entity => {
        content.push({
          value: entity.id,
          text: entity.humanId + ' - ' + entity.holder.fullName + ' (Lead)',
          isLead: true,
        });
      });
    } else {
      console.error("Unerwartete API-Antwortstruktur für Leads", leadResponse.data);
    }

    // Überprüfen der Struktur der API-Antwort für Deals
    if (dealResponse.data && Array.isArray(dealResponse.data)) {
      dealResponse.data.forEach(entity => {
        content.push({
          value: entity.id,
          text: entity.name + ' (Deal)',
          isLead: false,
        });
      });
    } else {
      console.error("Unerwartete API-Antwortstruktur für Deals", dealResponse.data);
    }

    this.leads = content;
  } catch (error) {
    console.error("Fehler beim Laden der Leads und Deals", error);
  }
},
    // load peoples for use when making relation with activity
    loadPeoples() {
      axios.get('/realm-people')
        .then((response) => {
          let content = []
          let peopleContent = []
          response.data && response.data.forEach(entity => {
            peopleContent.push({
              value: entity.id,
              text: entity.name
            })
            entity.realmPeopleEmails && entity.realmPeopleEmails.forEach(emailItem => {
              content.push({
                value: emailItem.id,
                text: emailItem.email,
                flag: 1,
              })
            })
          })
          this.guests = content
          this.peoples = peopleContent
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.tableShowLoading = false
        })
    },
    // load groups for use when making relation with activity
    loadGroups() {
      axios.get('/realm-group')
        .then((response) => {
          let content = []
          response.data && response.data.map(entity => {
            content.push({
              value: entity.id,
              text: entity.title
            })
          })
          this.groups = content
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.tableShowLoading = false
        })
    },
    /* when select people */
    onSelectPeople(selectedPeoples, lastSelectPeople) {
      this.selectedPeoples = selectedPeoples
      this.lastSelectPeople = lastSelectPeople
    },
    /* when select guest */
    onSelect(selectedGuests, lastSelectGuest, eventName) {
      this.selectedGuests = selectedGuests
      this.lastSelectGuest = lastSelectGuest

      if (eventName === 'delete') {
        if (lastSelectGuest.flag === 2) {
          const index = this.guests.findIndex(guest => guest.text === lastSelectGuest.text)
          if (index !== -1) {
            this.guests.splice(index, 1)
          }
        }
      }
    },
    focusOnExtra(key) {
      this.focusedExtra = key
    },
    // initialize the activity modal fields
    initialize(selectedDealId = null) {
      this.title = ''
      this.type = activityTypes[0].value
      this.selectedGuests = []
      this.location = ''
      this.freeType = 'FREE'
      this.notes = ''
      this.description = ''
      this.done = false
      this.focusedExtra = ''
      this.selectedActivityId = null

      if (selectedDealId) { // if this activity belongs to deal
        const index = this.leads.findIndex(entity => ((parseInt(entity.value) === parseInt(selectedDealId)) && (entity.isLead === false)))
        if (index !== -1) {
          this.lead = {
            value: this.leads[index].value,
            text: this.leads[index].text,
            isLead: false,
          }
        }
      } else {
        const leadId = this.$route.params.id
        const index = this.leads.findIndex(entity => ((parseInt(entity.value) === parseInt(leadId)) && (entity.isLead === true)))
        if (index !== -1) {
          this.lead = {
            value: this.leads[index].value,
            text: this.leads[index].text,
            isLead: true,
          }
        }
      }

      this.initializeDateTime(new Date())
    },
    initializeDateTime(date) {
      // set start time as 15 minutes later
      this.startDate = new Date(date.getTime() + 15 * 60000)
      this.startTime = ("0" + this.startDate.getHours()).slice(-2) + ':' + ("0" + ((this.startDate.getMinutes()) - (this.startDate.getMinutes() % 15))).slice(-2)
      // set end time as 30 minutes later
      this.endDate = new Date(date.getTime() + 30 * 60000)
      this.endTime = ("0" + this.endDate.getHours()).slice(-2) + ':' + ("0" + ((this.endDate.getMinutes()) - (this.endDate.getMinutes() % 15))).slice(-2)
      this.selectedDate = new Date()
    },
    // initialize the activity modal when click detail button on calendar view
    initializeOnClickDetail(entity) {
      this.title = (entity && entity.title) || ''
      this.type = (entity && entity.type) || activityTypes[0].value
      this.selectedGuests = []
      this.location = ''
      this.freeType = 'FREE'
      this.notes = ''
      this.description = ''
      this.done = false
      this.focusedExtra = ''
      this.selectedActivityId = null
      this.lead = {
        value: null,
        text: '',
      }
      this.startDate = new Date(entity && entity.startTimeObj)
      this.startTime = ("0" + this.startDate.getHours()).slice(-2) + ':' + ("0" + ((this.startDate.getMinutes()) - (this.startDate.getMinutes() % 15))).slice(-2)
      this.endDate = new Date(entity && entity.endTimeObj)
      this.endTime = ("0" + this.endDate.getHours()).slice(-2) + ':' + ("0" + ((this.endDate.getMinutes()) - (this.endDate.getMinutes() % 15))).slice(-2)
      this.selectedDate = this.startDate
    },
    // initialize the activity modal fields when updating activity
    initializeWithEntity(entity) {
    console.log('Initializing with entity:', entity);
    
    const startTime = new Date(entity.startTime);
    const endTime = new Date(entity.endTime);
    
    console.log('Parsed start time:', startTime);
    console.log('Parsed end time:', endTime);

    const startTimeSplits = startTime.toTimeString().split(' ')[0].split(':');
    const endTimeSplits = endTime.toTimeString().split(' ')[0].split(':');

    this.title = entity.title;
    this.type = entity.type;
    this.startDate = startTime;
    this.startTime = startTimeSplits[0] + ':' + startTimeSplits[1];
    this.endDate = endTime;
    this.endTime = endTimeSplits[0] + ':' + endTimeSplits[1];
    this.selectedDate = startTime;
    this.focusedExtra = '';
    this.selectedActivityId = entity.id;
    
    // Log new values to ensure they are set correctly
    console.log('Title:', this.title);
    console.log('Type:', this.type);
    console.log('Start Date:', this.startDate);
    console.log('Start Time:', this.startTime);
    console.log('End Date:', this.endDate);
    console.log('End Time:', this.endTime);
    console.log('Selected Date:', this.selectedDate);

    // Initialize selected guests
    const selectedGuests = entity.guests.split(",");

    selectedGuests && selectedGuests.forEach(selectedGuest => {
      if (selectedGuest.trim() !== '') {
        const index = this.guests.findIndex(guest => guest.text === selectedGuest);
        if (index !== -1) {
          this.selectedGuests.push({
            flag: 1,
            value: this.guests[index].value,
            text: selectedGuest
          });
        } else {
          this.selectedGuests.push({
            flag: 2,
            value: selectedGuest,
            text: selectedGuest
          });

          this.guests.push({
            flag: 2,
            value: selectedGuest,
            text: selectedGuest
          });
        }
      }
    });

    this.location = entity.location;
    this.freeType = entity.freeType;
    this.description = entity.description;
    this.notes = entity.notes;
    this.done = entity.done;
    
    // If this activity is linked with lead
    if (entity.lead) {
      this.lead = {
        value: entity.lead.id,
        text: `${entity.lead.humanId} - ${entity.lead.holder.fullName} (Lead)`,
        isLead: true
      };
    }
    // If this activity is linked with deal
    if (entity.deal) {
      this.lead = {
        value: entity.deal.id,
        text: `${entity.deal.name} (Deal)`
      };
    }

  this.selectedGroup = {
    value: null,
    text: ''
  };

  // If task is related to group
  const groupId = entity['realmGroup.id'];
  if (groupId) {
    this.selectedGroup = {
      value: groupId,
      text: entity['realmGroup.title']
    };
  }

  this.selectedPeoples = [];
  let i = 0;
  while (entity[`taskPeoples.${i}.realmPeople.id`]) {
    this.selectedPeoples.push({
      value: entity[`taskPeoples.${i}.realmPeople.id`],
      text: entity[`taskPeoples.${i}.realmPeople.name`]
    });
    i++;
  }
}
,
    submit(selectedTaskId) {
      this.submitted = true;
      /**
       * @description validate form fields
       */
      if (!this.validateFields()) {
        return;
      }

      const data = {
        title: this.title,
        type: this.type,
        startTime: this.makeDateTime(this.startDate, this.startTime),
        endTime: this.makeDateTime(this.endDate, this.endTime),
        guests: this.selectedGuests.map(item => item.text).toString(),
        location: this.location,
        description: this.description,
        freeType: this.freeType,
        notes: this.notes,
        done: this.done,
      };

      // if lead linked to this activity
      if (this.lead.value) {
        if (this.lead.isLead) { // if lead selected
          data['lead'] = {
            id: this.lead.value,
          };
        } else { // if deal selected
          data['deal'] = {
            id: this.lead.value,
          };
        }
      } else {
        // if people linked to this activity
        if (this.selectedPeoples.length > 0) {
          data['taskPeoples'] = [];
          this.selectedPeoples.map(entity => {
            data['taskPeoples'].push({
              realmPeople: {
                id: entity.value
              }
            });
          });
        }

        // if group linked to this activity
        if (this.selectedGroup.value) {
          data['realmGroup'] = {
            id: this.selectedGroup.value
          };
        }
      }

      if (!selectedTaskId) { // when clicked create activity
        axios.post('/task', data)
          .then((response) => {
            this.initialize();
            this.closeModal();
            this.applyUpdate(response.data);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {

          });
      } else { // when clicked edit activity
        axios.put(`/task/${selectedTaskId}`, data)
          .then((response) => {
            this.initialize();
            this.closeModal();
            this.applyUpdate(response.data);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {

          });
      }
    },
    validateFields() {
      this.errors = {};
      // validate title
      if (this.title.trim() === '') {
        this.errors['title'] = true;
      }
      // validate date fields
      if (this.startTime === '' || this.endTime === '') {
        this.errors['time'] = true;
      } else {
        const startDateTime = this.makeDateTime(this.startDate, this.startTime);
        const endDateTime = this.makeDateTime(this.endDate, this.endTime);

        if (startDateTime >= endDateTime) {
          this.errors['time'] = true;
        }
      }

      return Object.keys(this.errors).length === 0;
    },
    /**
     * @description replace date's hour and minutes with provided time string
     * @param date
     * @param timeStr
     */
     makeDateTime(date, timeStr) {
      console.log('Date and time string:', date, timeStr);
      const retDate = new Date(date);
      const hoursMins = timeStr.split(':');

      if (hoursMins.length >= 2) {
        retDate.setHours(parseInt(hoursMins[0]));
        retDate.setMinutes(parseInt(hoursMins[1]));
      }

      console.log('Constructed datetime:', retDate);
      return retDate;
    },

    onKeyDown(e) {
      if (e.keyCode === 13) {
        this.insertAsNewOption(this.newGuest);
      } else {
        // add character of keyCode because newGuest is still not updated
        this.errors['newGuest'] = !this.validateEmail((this.newGuest + String.fromCharCode(e.keyCode)).trim());
      }
    },
    /**
     * @description when click add button on new guest input
     * @param value
     */
    insertAsNewOption(value) {
      if (value.trim() !== '') {
        this.newGuestAddTried = true;

        if (this.validateEmail(value.trim())) {
          this.guests.push({
            flag: 2,
            value: value,
            text: value
          });
          this.selectedGuests.push({
            flag: 2,
            value: value,
            text: value
          });
          this.newGuest = '';

          this.newGuestAddTried = false;
        } else {
          this.errors['newGuest'] = true;
        }
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    // check if guests, location, description are all empty
    checkAllEmpty() {
      return (this.focusedExtra === '' && (this.selectedGuests.length === 0) && (!this.location) && !this.description);
    },
    // when click next on calendar
    calendarNext() {
      this.$refs.vuecal.next();
      const tomorrowDate = this.selectedDate.getDate() + 1;
      const tomorrow = new Date(this.selectedDate);
      tomorrow.setDate(tomorrowDate);
      this.selectedDate = tomorrow;
    },
    calendarPrevious() {
      this.$refs.vuecal.previous();
      const yesterdayDate = this.selectedDate.getDate() - 1;
      const yesterday = new Date(this.selectedDate);
      yesterday.setDate(yesterdayDate);
      this.selectedDate = yesterday;
    },
    getActivitiesOnSelectedDate() {
      const startTime = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), 0, 0, 0);
      const endTime = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate() + 1, 0, 0, 0);

      const data = {
        startTime: startTime.toISOString(), // Konvertieren Sie das Datum in ISO-String
        endTime: endTime.toISOString() // Konvertieren Sie das Datum in ISO-String
      };

      axios.post(`/task/date`, data)
        .then(response => {
          this.alreadyCreatedEvents = [];
          response && response.data && response.data.map(item => {
            if (item.id !== this.selectedActivityId) {
              const event = {
                start: commons.formatDate(new Date(item.startTime)),
                end: commons.formatDate(new Date(item.endTime)),
                title: this.getPlaceholder(item.type) + ' - ' + item.title,
                content: '',
                class: 'already-created',
                draggable: false,
                resizable: false,
              };
              this.alreadyCreatedEvents.push(event);
            }
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>

<style>
.tox-tinymce {
  height: 200px !important;
}

.date-selector:nth-child(1) {
  padding-right: 0;
}

.time-selector {
  padding-right: 0;
}

.vdp-datepicker input {
  height: 33px;
  width: 100%;
  border: 1px #d8dbe0 solid;
  padding-left: 13px;
}

.vdp-datepicker input:focus {
  border: 1px #ccc7f6 solid;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.vuecal__event.other {
  background-color: rgb(74 138 230);
  color: white;
}

.vuecal__event.current {
  background-color: #05B9AE;
  color: white;
}

.vuecal__event.already-created {
  background-color: #2580b1;
  color: white;
}

.vuecal__flex.vuecal__title {
  font-size: 14px;
}

.vuecal__title-bar {
  background-color: transparent;
  display: none;
}

.calendar-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>
